import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Link, SignupButton, Row, Typography, Container, Section, SectionTitle, Article, FeatureGrid, FeatureRows } from "components/ui"
import Image from "components/image"

const BackupNotifications = () => (
  <Layout>
		<SEO refKey="backup-schedule-and-retention" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="lg:grid grid-cols-12 gap-20">
            <div className="lg:col-span-6">
              <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                Simple backup scheduling and retention
              </h1>
              <p className="mt-6">
                Whether you want to schedule a file or database backup you can schedule it down to the minute and in
                your preferred time zone without requiring any technical skills.<br/>
              </p>
              <Typography tag="p" className="mt-2 mb-6">
                <strong>Available scheduling options:</strong> Daily, Weekly, Monthly, Custom, Cron syntax.
              </Typography>
              <p><Link href="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/scheduling/oUcnHgJRFic7jXNNoKjpPK" arrow="right">See our documentation</Link></p>
              <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Schedule your first backup now"/>
            </div>
            <div className="hidden lg:col-span-6 lg:block">
                <Image filename="simplebackups-scheduling.png" alt="Backup scheduling for file and database" style={{maxWidth: '494px'}}/>
            </div>
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <FeatureRows items={
          [
            {
              title: "Configure how many backups you want to keep",
              description: (
                <>
                  <Typography tag="p" className="mt-2 mb-6">
                    Define how many backup archives should be kept, SimpleBackups takes care of the cleanup.<br/>
                    Save storage and reduce cost by keeping the backups that you know should be kept, not more.
                  </Typography>
                  <p><Link href="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/retention/p7Gk59Va5Av3Sigov9oRYE" arrow="right">See our documentation</Link></p>
                </>
              ),
              image: (<Image filename="simplebackups-retention.png" alt="SimpleBackups file and database, flexible scheduling and retention policies" style={{maxWidth: '607px'}}/>)
            }]}/>
        </Row>
      </Container>
    </Section>

    <Section color="dark">
      <Container>
        <Row>
          <div className="col-md-12">
            <SectionTitle
              title={(<>How to get started.</>)}
              tag="h2"
              align="left"
            />
            <FeatureGrid cols="3" className="mt-8">
              <Article link="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/scheduling/oUcnHgJRFic7jXNNoKjpPK">Our documentation section on backup scheduling.</Article>
              <Article link="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/retention/p7Gk59Va5Av3Sigov9oRYE">Our documentation section on backup retention.</Article>
            </FeatureGrid>
          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "backup-schedule-and-retention",
    title: "Flexible backup scheduling and retention",
    description: "Schedule your server, database, snapshot, storage backup and define how many archives should be kept, without code.",
  }
}

export default BackupNotifications
